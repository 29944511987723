<template>
    <div
        class="mobile-menu"
        @click="$emit('close')"
        :class="{
            'mobile-menu_login': isAuth
        }"
    >
        <div
            @click.stop=""
            class="mobile-menu__wrapper"
        >
            <div class="mobile-menu__top">
                <a href="/" class="logo"></a>
                <span
                    @click="$emit('close')"
                    class="mobile-menu__close"
                ></span>
            </div>
            <div
                v-if="isAuth"
                class="mobile-menu__content"
                @click="$emit('close')"
            >
                <Menu/>
            </div>
            <div class="mobile-menu__content" @click="$emit('close')">
                <ul class="mobile-menu__list">
                    <li class="d-flex justify-content-start">
                        <router-link to="/" class="d-flex align-items-center">
                            <div class="home-icon img-wrapper d-flex align-items-center justify-content-center">
                                <img src="./assets/home.svg" alt="home icon">
                            </div>
                            Главная
                        </router-link>
                    </li>
                </ul>
                <div class="d-flex justify-content-center">
                    <base-button
                        link
                        mode="yellow"
                        v-if="!isAuth"
                        class="mobile-menu__login"
                        to="/login"
                    >
                        Войти
                    </base-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './mobile-menu.scss';
import { mapGetters } from 'vuex'

import contacts from '@/mixins/contacts'

import Menu from '@/applications/personal-area/components/menu/Menu'

export default {
    name: 'MobileMenu',
    mixins: [
        contacts
    ],
    data() {
        return {
            menu: [
                {
                    name: 'Как получить',
                    anchor: 'how-get'
                },
                {
                    name: 'Условия',
                    anchor: 'conditions'
                },
                {
                    name: 'Вопрос/ответ',
                    anchor: 'faq'
                }
            ],
            calculator: 10000,
        }
    },
    watch: {
        '$route'() {
            this.$emit('close')
        }
    },
    computed: {
        ...mapGetters({
            user: 'application/user'
        }),
        isAuth() {
            return !!this.user.contactData.id
        },
    },
    beforeDestroy() {
        document.body.style.overflow = 'auto';
        document.body.style.touchAction = 'auto';
    },
    beforeCreate() {
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
    },
    components: {
        Menu,
    }
};
</script>
